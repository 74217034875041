@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

:root {
    //color
    --black: #000000;
    --white: #ffffff;
    --green: #638F42;
    --brown: #784A20;
    --semi-black: #261E16;

    //font style
    --font-primary: 'Roboto', sans-serif;

    //font color
    --color-primary: var(--black);
    --color-secondary: var(--green);
}