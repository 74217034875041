@import "vars";

body {
    font-family: var(--font-primary);/*update this to site font*/
    font-size: 16px;
    color: var(--color-primary); /* update this to site body colour */
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}

.clear-float {
    clear:both;
}

.highlight {
    color:#bbbbbb;
    font-size:40px;
}

.button {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    padding:.75rem 2rem;
    max-width: 100%;
    color: #FFF;
    background-color: var(--green);
    border: 1px solid var(--green);
    border-radius: 25px;
    box-sizing: border-box;
    transition: all 0.3s ease;
    font-size: clamp(16px, 3vw, 18px);
    cursor: pointer;
}
.button:hover {
    background-color: var(--semi-black);
    border-color: var(--semi-black);
}

img.display-left {
    float:left;
    max-width: 50%;
    margin-right:20px;
    margin-bottom:10px;
}

img.display-right {
    float:right;
    max-width: 50%;
    margin-left:20px;
    margin-bottom:10px;
}

@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
}

.text-green{
    color: var(--green);
}

.text-brown{
    color: var(--brown);
}


.heading{
    font-size: clamp(24px, 5vw, 60px);
    font-weight: 400;
}

.sub-heading{
    font-size: clamp(20px, 4vw, 36px);
    font-weight: 400;
}
.sub-heading-small{
    font-size: clamp(16px, 3.6vw, 24px);
    font-weight: 400;
}

.page-content{
    a[href$='.pdf'], a[href$='.docx'], a[href$='.doc'], 
    a[href$='.ppt'], a[href$='.pptx'], a[href$='.mp4'],
    a[href$='.ppsx'], .file-upload{
        color: var(--green);
        text-decoration: none;
        font-size: clamp(16px, 3.6vw, 24px);
        &:before{
            display: inline-block;
            content:url("../assets/img/document.svg");
            margin-right: 10px;
            width:36px;
            height: 47px;
            transform: translateY(25%);
        }
    }
}